@import "../../constants/global.scss";

.checkOutPage {
    width: 100%;
    height: 100%;

    .checkoutBx {
        height: 100%;
        overflow: auto;

        .checkoutTxt {
            font-size: 24px;
            font-weight: bold;
            line-height: 50px;
            margin-top: 10px;
            padding-left: 20px;
        }

        .checkoutContent {
            padding-bottom: 60px;
        }
    }
}

@media only screen and (min-width: 769px) {
    .checkOutPage {
        .checkoutBx {
            max-width: 1480px;

            padding: 0 70px;
            margin: 0 auto;

            .checkoutContent {
                padding-bottom: 0;
                display: flex;
                min-height: calc(100% - 180px);
                justify-content: space-between;

                .checkoutContentSection {
                    width: 50%;
                    max-width: 480px;
                }
            }
        }
    }
}

.couponInputBx {
    display: flex;
}

.couponSaveBtn {
    min-width: 120px;
    margin-left: 10px;
    >button{
        height: 40px;
    }
}

.errorText {
    color: var(--error-color);
    font-size: 12px;
    margin-top: 10px;
}

.validText {
    color: var(--theme-text-color);
    font-size: 12px;
    margin-top: 10px;
}

.defaultText {
    color: #505050;
    font-size: 12px;
    margin-top: 10px;
}

.promotionQuestion {
    margin-left: 10px;
}

.normalTitle {
    font-size: 16px;
}

@media only screen and (max-width: 480px) {
    .addTipsPanelInner {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 0;
        border-radius: 0 !important;
    }

    .tipsSubmitBtn {
        width: 90%;
        margin-left: 5%;
        margin-top: 10%;
    }

    .tipsBxDetail {
        width: 80%;
        margin-left: 10%;
        text-align: center;
        font-size: 18px;
    }

    .tipSuggestionBtn {
        font-size: 14px !important;
    }

    .addNewCard {
        font-size: 14px;

        span {
            font-size: 14px;
        }
    }

    .promotion {
        font-size: 14px;
        margin: 0.3rem 0 0.8rem;
        color: #818da0
    }

    .promotionTip {
        margin-right: 0.3rem;
    }

    .othersTipBtn {
        font-size: 12px;
    }

    .tipSuggestionBtn {
        padding: 0 2px;
    }
}

@media only screen and (min-width: 481px) {
    .addTipsPanelInner {
        left: 25%;
        right: 25%;
        top: 25%;
        bottom: 25%;
    }

    .tipsSubmitBtn {
        width: 50%;
        margin-left: 25%;
        margin-top: 10%;
    }

    .tipsBxDetail {
        width: 50%;
        margin-left: 25%;
        text-align: center;
        font-size: 20px;
    }

    .addNewCard {
        font-size: 20px;

        span {
            font-size: 16px;
        }
    }

    .promotion {
        font-size: 16px;
        margin: 15px 0 20px;
        color: #818da0
    }

    .promotionTip {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 1367px) {
    .addTipsPanelInner {
        left: calc(50% - 342px);
        width: 683px;
        top: 25%;
        bottom: 25%;
    }
}

.cardBx {
    @include cardBx;
    box-shadow: none;
}

.cardTitle {
    @include cardTitle;
}

.cardContent {
    @include cardContent;
}

.deliveryInfo {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;

    &.deliveryInfoSub {
        color: #666;
    }
}

.paymentBx .cardTitle {
    width: 100%;
}

.addNewCard {
    color: #fff;
    margin-top: 10px;

    span {
        display: inline-block;
        padding: 4px 10px;
        border: 1px solid var(--theme-color);
        background: var(--theme-color);
        border-radius: 4px;
        cursor: pointer;
    }
}

.paymentListBox {
    .paymentItemBx {
        flex: 0 0 36px;
    }
}

.paymentItemBx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-left: 5px;
    // height: 40px;
    // line-height: 40px;
    font-size: 16px;
    cursor: pointer;

    &.active {
        color: var(--theme-text-color);
    }

    &:hover {
        // background: #fff3de;
    }
}

.paymentItemBxDisable {
    display: flex;
    flex: 0 0 36px;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    color: #999;
    font-size: 16px;
    filter: grayscale(1);
}

.cardNumber,
.cashText {
    display: inline-block;
    // width: calc(90% - 26px);
    margin-left: 10px;
}

.expireDate {
    display: inline-block;
    // width: 60px;
    margin-left: 5%;
}

.sumContentLine {
    height: 50px;
    line-height: 50px;
}

.sumContentKey,
.sumContentValue {
    display: inline-block;
    width: 50%;
}

.sumContentKey {
    white-space: nowrap;
}

.sumContentValue {
    text-align: right;
}

.sumTotalBx {
    border-top: 1px solid var(--border-color);
    font-weight: bold;
}

.checkoutBtn {
    width: 100%;
    margin-top: 15px;
}

.selectedBtn {
    background-color: var(--theme-color);
    color: #fff;
}

.mobileCheckOutBx {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0.44rem;
    width: 100%;
    background: var(--white-background-color);
    box-shadow: 0px -2px 4px 2px rgba(228, 228, 228, 0.4);
    display: flex;
    justify-content: space-between;
}

.mobileTotal {
    display: inline-block;
    width: 20%;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 18px;
    color: var(--theme-text-color);
    font-weight: 700;
}

.mobileCheckoutBtn {
    display: inline-block;
    // width: 70%;
    margin-left: 3%;

    >div {
        padding: 0 30px;
    }
}

.addTipsPanelOuter {
    @include floatPanel;
}

.addTipsPanelInner {
    @include floatPanelInner;
    z-index: 60;
}

.addTipsPanelContent {
    position: relative;
    margin: 0 5%;
    top: 8%;
    height: 90%;
}

.tipsBx {
    display: flex;
    height: calc(90% - 50px);
    align-items: center;
    justify-content: center;
}

.tipBxInner {
    width: 90%;
}

.tipsInputBx,
.dollarSign {
    display: inline-block;
}

.tipsInputBx {
    width: 100%;
}

.tipsInputBxFocus {

    .dollarSign,
    .tipsInput {
        border-color: var(--theme-color);
    }
}

.dollarSign {
    width: 20%;
    text-align: center;
    line-height: 42px;
    height: 42px;
    border-radius: 10px 0 0 10px;
    border: 1px solid #ccc;
    vertical-align: middle;
    transition: border 0.3s;
}

.tipsInput {
    height: 42px;
    line-height: 42px;
    outline: none;
    border: none;
    width: calc(80% - 25px);
    font-size: 18px;
    padding: 0 5px;
    text-align: center;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-radius: 0 10px 10px 0;
    background-color: rgb(246, 246, 248);
    vertical-align: middle;
    transition: border 0.3s;
}

.tipsInput::-webkit-inner-spin-button,
.tipsInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.checkOutPageFooterBx {
    height: 60px;
    margin-top: 30px;
}

.checkOutPageMobileFooterBx {
    height: 60px;
}

.couponCodeBx {
    background: #f6fbfe;
}

.cardBxBg {
    @include cardBxBg;

    .cardTitle {
        font-size: 17px;
        font-weight: 600;
        line-height: 28px;
    }

    .tipListBx {
        margin-top: 15px;
    }

    .cashText {
        width: auto;
    }

    .itemContentTips {
        font-weight: normal;
        font-size: 14px;
        color: #919191;
    }

    .cardNumber,
    .cashText,
    .expireDate {
        font-size: 14px;
    }
}

.notSupportCardTip {
    font-size: 13px;
    color: #666;
    line-height: 20px;
    padding: 10px 5px 10px 5px;
    word-break: break-all;

    span {
        color: var(--error-color);
    }
}

.selectedPaymentTips {
    display: flex;
    font-size: 14px;
    color: #333;
    line-height: 20px;
    padding: 10px 5px 0;
    vertical-align: middle;
    word-wrap: break-word;

    i {
        font-style: normal;
    }

    .note {
        color: var(--theme-text-color);
        margin-right: 4px;
    }

    b {
        color: var(--theme-text-color);
    }
}

.notSelectPayment {
    color: var(--error-color);
}

.cardTitle {
    width: 100%;
}

.tipAmount {
    float: right;
    font-size: 14px;
}

.cardInfo {

    // color:#000;
    span {
        font-size: 12px;
        padding: 0 3px;
    }
}

.cardIcon {
    font-weight: 700;
    font-size: 20px;
    vertical-align: -2px;
}

.cardPaymentBx {
    margin-top: 15px;
    padding-left: 5px;
    // margin-left: 20px;
}

.cashPayment {
    display: flex;
    flex-wrap: wrap;
}

.cardManager {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 400px;
    overflow: hidden;

    .cardManagerInnner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        transition: transform 0.3s;
    }
}

.paymentListBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 0 0 100%;
    overflow: hidden;

    .paymentListTitle {
        padding: 10px 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.6;
    }

    .paymentInnerBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-bottom: 10px;
        overflow-y: auto;
    }

    .addNewCard {
        // width: 240px;
        height: 36px;
        margin-left: 5px;
        padding: 0 15px;
        line-height: 36px;
        border-radius: 20px;
        font-size: 16px;
        border: 1px solid var(--theme-color);
        color: var(--theme-text-color);
        text-align: center;

        &:active {
            opacity: 0.8;
        }
    }

    .paymentConfirmBx {
        display: flex;
        padding: 10px 5px;
        box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);

        span {
            display: block;
            flex: 1;
            height: 42px;
            line-height: 42px;
            text-align: center;
            padding: 0 20px;
            border-radius: 10px;
            font-size: 16px;
            border: 1px solid #FFF;
            cursor: pointer;

            &.cancelBtn {
                border-color: var(--theme-color);
                background: #FFF;
                color: var(--theme-text-color);
            }

            &.confirmBtn {
                background: var(--theme-color);
                color: #FFF;
            }
        }
    }
}

.summarySavingMoney {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;

    .savingIcon {
        width: 20px;
        height: 20px;
    }

    .savingCont {
        font-size: 13px;
        padding-top: 4px;
        padding-left: 10px;
        color: var(--theme-text-color);
        font-weight: 600;
    }
}