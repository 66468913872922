@import "../../constants/global.scss";
@import "../../constants/orderItem.scss";

.bagPageNew{
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: #f2f2f2;
	.topTipWrap{
		padding: 10px 12px 10px 16px;
		background-color: var(--theme-opacity-color);
		border-bottom: 1px solid rgba(0,0,0,0.06);
		font-size: 12px;
		line-height: 18px;
		.topTip{
			max-width: 1440px;
			margin: 0 auto;
			display: flex;
			.saveMoneyIcon{
				width: 24px;
				height: 24px;
			}
			.saveMoneyContent{
				flex: 1;
				padding-left: 16px;
				.saveMoneyTextMain{
					font-size: 16px;
					font-weight: 600;
					color: var(--theme-text-color);
					line-height: 20px;
				}
				.saveMoneyText{
					color: #000;
					span{
						color: var(--theme-text-color);
						text-decoration: underline;
						cursor: pointer;
					}
				}
			}
		}
	}
	.bagPageBody{
		height: 0;
		flex: 1;
		padding: 16px;
		overflow: auto;
		
		.bagContent{
			.loyaltyMember{
				padding: 12px 24px;
				background: #fff;
				border-radius: 10px;
				margin-bottom: 22px;
				background: url(../../images/rewardsBg.png) center center no-repeat;
				background-size: cover;
				box-shadow: 0px 2px 10px rgba(18, 1, 54, 0.1);
				.title{
					font-weight: 600;
					font-size: 16px;
					line-height: 22px;
					letter-spacing: -0.02em;					
					color: #120136;
					margin-bottom: 8px;
				}
				.content{
					font-size: 14px;
					line-height: 19px;
					color: #120136;
					min-height: 40px;
					span{
						font-weight: 600;
						padding-left: 2px;
						color: #120136;
						&::before{
							content: "";
							display: inline-block;
							width: 16px;
							height: 16px;
							background: url(../../images/subtract.png) 0 0 no-repeat;
							background-size: 100%;
							vertical-align: middle;
							margin-right: 2px;
							margin-top: -2px;
						}				
					}
				}
				.joinFree{
					text-align: right;
					.joinFreeBtn{
						display: inline-block;
						width: 70px;
						height: 22px;
						line-height: 22px;
						background: var(--theme-color);
						border-radius: 40px;
						font-size: 12px;
						font-weight: 600;
						text-align: center;
						color: var(--color-btn-text);
						letter-spacing: -0.02em;
						cursor: pointer;
					}
				}
			}
			.bagContentFooter{
				height: 80px;
			}
			.notSupportCardTip{
				font-size: 13px;
				color: #666;
				line-height: 20px;
				padding: 10px 5px 10px 5px;
				word-break: break-all;
				span{
					color: var(--error-color);
				}
			}
			.notificationMessage{
				padding: 7px 14px;
				font-weight: normal;
				line-height: 20px;
				font-size: 14px;
				color: #000;
				border-radius: 10px;
				background-color: var(--theme-opacity-color);
				box-shadow: 0 2px 8px rgba(0,0,0,0.08);
				margin-bottom: 22px;
			}
			
			.cardSection{
				background-color: #fff;
				border-radius: 10px;
				margin-bottom: 16px;
				padding: 8px 16px;
				
				&.tipCardSection .cardContent{
					padding-bottom: 0;
				}
			}
			
			.cardBx{
				padding-top: 8px;
				.cardTitle{
					font-size: 17px;
					font-weight: 600;
					line-height: 28px;
					.validationText{
						display: inline-block;
						padding-left: 4px;
						padding-right: 4px;
						font-size: 14px;
						color: #f12202;
						display: inline-block;
						padding-right: 4px;
					}
					span.tipAmount{
						float: right;
					}
				}
				
				.cardContent{
					padding: 12px 0;
					font-size: 14px;
					.orderTypeBtn{
					  span{
					    display: inline-block;
					    width: 96px;
					    height: 36px;
					    line-height: 34px;
					    margin-right: 15px;
					    border-radius: 10px;
					    font-size: 14px;
					    text-align: center;
					    background: var(--white-background-color);
					    border: 1px solid var(--theme-color);
					    color:var(--theme-text-color);
					    cursor: pointer;
					    letter-spacing: -0.46px;
						font-weight: 600;
					  }
					  .selectedOrderType{
					    background: var(--theme-color);
					    color: var(--color-btn-text);
					    border: none;
					  }
					}
					
					.switchOutter{
						width: 160px;
						height: 32px;
						position: relative;
						text-align: center;
						line-height: 32px;
						border-radius: 10px;
						overflow: hidden;
						.switchBg{
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: 1;
							background-color: var(--theme-color);
							opacity: 0.15;
							border-radius: 10px;
						}
						.switchThumb{
							position: absolute;
							top: 0;
							left: 0;
							width: 80px;
							height: 32px;
							background-color: var(--theme-color);
							z-index: 2;
							border-radius: 10px;
							transition: left 0.25s;
							&.slide{
								left: 80px;
							}
						}
						.switchCon{
							width: 100%;
							height: 32px;
							top: 0;
							left: 0;
							position: absolute;
							z-index: 3;
							display: flex;
							.switchOption{
								flex: 1;
								color: var(--color-btn-text);
								transition: color 0.25s;
								cursor: pointer;
								font-size: 14px;
								font-weight: 600;
							}
						}
					}
					
					.timePickerBx{
					  position: relative;
					  width: 100%;
					  margin-bottom: 10px;
					  background-color: #F2F2F2;
					  select{
					    width: calc(100% - 30px);
					    height: 40px;
					    border:none;
					    border-radius: 0;
					    outline: none;
					    background-color: #F2F2F2;
						background-repeat: no-repeat;
						background-position: right center;
						background-size: 16px;
						padding: 0;
						margin: 0 15px;
					    color: #000;
						font-size: 14px !important;
					  }
					  .timeSelectIcon{
						  position: absolute;
						  right: 14px;
						  top: 12px;
						  font-size: 14px;
					  }
					}					
					.fullName,.phoneNumber,.contactEmailCon{
						display: flex;
						align-items: center;
						&:before{
						  content: ' *';
						  color: #f00;
						  font-size: 16px;
						  margin-right: 5px;
						}					
					}	
					.error-tips{
						padding-left: 14px;
						margin-top: 4px;
						line-height: 12px;
						font-size: 12px;
						color: var(--error-color);
					}
					.contactEmailCon{
						height: 0px;
						overflow: hidden;
						padding: 0;
						transition: all 0.3s;
						&.show{
							height: 51px;
							padding-top: 11px;
							overflow: initial;
						}
						
						.contactEmail{
							width: 100%;
							display: flex;
							align-items: center;
							position: relative;
							.emailOptions{
								position: absolute;
								background-color: #fff;
								left: 2px;
								right: 3px;
								top: 40px;
								box-shadow: 0 3px 8px rgba(0,0,0,0.3);
								z-index: 9;
								border-bottom-left-radius: 5px;
								border-bottom-right-radius: 5px;
								.emailOption{
									font-size: 13px;
									line-height: 20px;
									border-bottom: 1px solid rgba(150,150,150,0.1);
									padding-left: 5px;
									cursor: pointer;
									overflow:hidden;
									text-overflow: ellipsis;
									&:hover{
										background-color: var(--theme-opacity-color);
									}
								}
							}
							.overwriteEmailTip{
								display: fixed;
								top:0;
								left:0;
								right:0;
								bottom:0;
								background-color: rgba(0,0,0,0.4);
								z-index: 99;
								.tipCon{
									width: 320px;
									background-color: #fff;
									border-radius: 10px;
									padding: 25px 14px;
									position: absolute;
									z-index: 100;
									top: 50%;
									left: 50%;
									transform: translate(-50%,-50%);
									.desc{
										font-size: 14px;
										line-height: 30px;
										text-align: center;
										word-break: break-all;
									}
									.btn{
										width: 90px;
										height: 36px;
										text-align: center;
										background-color: var(--theme-color);
										border-radius: 5px;
										color: #fff;
										font-size: 15px;
										font-weight: 500;
										line-height: 36px;
										margin: 20px auto 0;
									}
								}
							}
						}
					}
					.phoneNumber{
						margin-top: 12px;
						.phoneInput{
							@include myInput;
							width: 0;
							flex: 1;
                            &::placeholder{
                                color: rgba(0,0,0,0.2);
                            }
						}
						.veryPhoneBtn{
							width: 100px;
							margin-left: 12px;
							text-align: center;
							padding: 0;
							height: 40px;
							font-size: 14px;
						}
                        i.clearInput{
                        	width: 1px;
                        	height: 1px;
                        	position: relative;
                        	font-size: 24px;
                        	color: #333333;
                        }
                        
                        i.clearInput::before{
                        	position: absolute;
                        	left:-40px;
                        	top: -12px;
                        	cursor: pointer;
                        }
                        i.clearInput.smallGap::before{
                            left:-28px;
                        }
					}
					
					.cashPayment{
						display: flex;
						flex-wrap: wrap;
						.paymentItemBx{
							margin: 0 12px 10px 0;
							font-size: 14px;
							>label{
								cursor: pointer;
							}
						}
					}
					
					.selectedPaymentTips{
						display: flex;  
						font-size: 12px;
						color: #333;
						line-height: 20px;
						padding: 10px 5px 0;
						vertical-align: middle;
						word-wrap: break-word;
						i{
							font-style: normal;
						}
						.note{
							color: var(--theme-text-color);
							margin-right: 4px;
						}
						b{
							color: var(--theme-text-color);
						}
					}
					.delayTimeTip{
						padding-top: 0;
					}
					
					.orderPriceDetailBx{
						padding: 6px 0;
						.itemContentLine{
							line-height: 12px;
							padding: 12px 0;
							display: flex;
							.itemContentKey{
								flex: 1;
								display: flex;
								.iconInfo{
									margin-left: 5px;
									margin-right: 5px;
									color: #6a6a6e;
									cursor: pointer;
								}
							}
							.itemContentValue{
								padding-left: 5px;
							}
							.discountColor{
								color: var(--theme-color);
							}
							&.total{
								border-top: 1px solid #f2f2f2;
								margin-top: 5px;
								padding-top: 16px;
							}
						}
					}
					
					.tipListBx{
						display: flex;
						flex-wrap: wrap;
						.tipSuggestionBtn{
							margin-right: 12px;
							margin-bottom: 3px;
							&:last-child{
								margin-right: 0;
							}
							.tipSuggestionInner{
								cursor: pointer;
								display: flex;
								align-items: center;
								justify-content: center;
								padding: 0 20px;
								color: #000;
								border-radius: 18px;
								border: 1px solid var(--theme-color);
								height: 36px;
								min-width: 50px;
								&:hover{
									box-shadow: 0 1px 2px rgba(0,0,0,0.2);
								}
								.othersTipBtn{
									font-size: 12px;
									line-height: 14px;
									padding: 0 7px;
								}
								&.selectedBtn{
									background-color: var(--theme-color);
									color: var(--color-btn-text);
									box-shadow: 0 1px 2px rgba(0,0,0,0.2);
								}
							}
						}
					}
					
					.deliverTip{
						padding-top: 10px;
						line-height: 22px;
						font-size: 12px;
						span{
							color: var(--theme-text-color);
						}
					}
					
					.summarySavingMoney{
						display: flex;
						align-items: center;
						justify-content: flex-end;
						margin-top: 5px;
						font-size: 16px;
						.savingIcon{
							width: 24px;
							height: 24px;
						}
						.savingCont{
							padding-top: 4px;
							padding-left: 10px;
							color: var(--theme-color);
							font-weight: 600;
						}
					}
					.summaryEarnPoints{
						display: flex;
						justify-content: flex-end;
						font-weight: 600;
						font-size: 16px;
						line-height: 22px;
						letter-spacing: -0.02em;
						color: #120136;
						margin-top: 12px;
						span{
							color: var(--theme-color);
						}
						.subtract{
							font-size: 22px;
							color: var(--theme-color);
							margin-right: 8px;
						}
					}
					
					&.deliveryAddress{
					
						.addressTxt{
							margin-bottom: 12px;
							padding-left: 6px;
						}
						.btn{
							background-color: var(--theme-color);
							color: var(--color-btn-text);
							width: 120px;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border-radius: 10px;
							cursor: pointer;
							font-size: 15px;
							font-weight:600;
							transition: all 0.2s;
							letter-spacing: 0;
							&:hover{
								box-shadow: 0 0 6px rgba(0,0,0,0.2);
							}
						}
						.deliveryInstruction{
							padding-top: 12px;
							display: flex;
							align-items: center;
						}
						.deliveryContactless{
							margin-top: 12px;
							display: flex;
							align-items: center;
							i{
								color: var(--theme-color);
								font-size: 20px;
								margin-top: 1px;
								cursor: pointer;
							}
							span{
								padding-left: 8px;
								color: #454545;
								cursor: pointer;
							}
						}
					}
					
				}
				
				.isNotAvailablePhone{
					font-size: 13px;
					line-height: 24px;
					padding-left: 16px;
					padding-top: 10px;
					color: var(--error-color);
				}
                
                .phoneLoginAgreement{
                    color: rgba(0, 0, 0, 0.70);
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: -0.3px;
                    padding-top: 8px;
                    padding-left: 16px;
                    span{
                        text-decoration: underline;
                        cursor: pointer;
                        padding: 0 1px;
                    }
                }
				
				&.couponCodeBx{
					.couponInputBx{
						display: flex;
						input{
							width: 0;
							flex: 1;
						}
						.couponSaveBtn{
							min-width: 100px;
							margin-left: 12px;
							>button{
								height: 40px;
							}
						}
					}
					.errorText,.defaultText,.validText{
						font-size: 12px;
						padding-left: 5px;
						line-height: 20px;
						margin-top: 6px;
					}
				}
			}
			
			.errorText{
				color: #C30052;
			}
			.defaultText{
				color: #666;
			}
			.validText{
				color: var(--theme-text-color);
			}
			
			.confirmBtn{
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 6px 0 0;
				background-color: rgba(242,242,242,0.8);
				z-index: 1;
				padding-bottom: 22px;
				padding-top: 15px;
				
				.floatBtn{
					width: 90%;
					margin: 0 auto;
					height: 46px;
					background: var(--theme-color);
					border-radius: 10px;
					box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.15);
					text-align: center;
					line-height: 46px;
					font-size: 16px;
					color: var(--color-btn-text);
					font-weight: 600;
					cursor: pointer;
				}
				&.confirmBtnGrey{
					.payErr{
						color: #f14e1a;
						line-height: 20px;
						text-align: center;
						font-size: 13px;
					}
					.floatBtn{
						cursor: not-allowed;
						box-shadow:  0 0 1.5px #333;
						background-color: #ccc;
						color: #999;
					}
				}
			}
		}
		
		.emptyBagContent{
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 50px 0;
			font-size: 16px;
			.emptyBagDetail{
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			}
            .emptyBag{
                text-align: center;
                .emptyBagBtn{
                    width: 160px;
                    height: 40px;
                    border-radius: 4px;
                    font-size: 16px;
                    cursor: pointer;
                    background-color: rgba(255,255,255,0.6);
                    margin-top: 20px;
                }
            }
		}
		
	}
	
	&.bagPageSelfDineIn{
		.bagPageBody{
			padding: 16px 0 ;
			.tableUserInfo{
				padding: 0 16px;
			}
			.bagContent{
				.cardSection{
					background-color: transparent;
					padding: 0 16px;
					.itemListBx{
						padding: 0 12px;
					}
				}
				.sdiCard{
					background-color: #fff;
					border-radius: 10px;
					padding: 8px 16px 0;
					margin: 16px;
				}
				.sdiItemList{
					box-shadow: 0 0 5px rgba(200, 200, 200, 0.2);
					font-size: 14px;
					margin: 16px 16px 0;
					padding-bottom: 10px;
					border-radius: 10px;
					background-color: #fff;
					.listHeader{
						display: flex;
						align-items: center;
						padding: 10px 16px;
						font-weight: 600;
						.merchantName{
							flex: 1;
							line-height: 22px;
						}
						.orderBtn{
							color: rgba(18, 1, 54, 1);
							height: 28px;
							font-size: 13px;
							line-height: 24px;
							border: 2px solid rgba(18, 1, 54, 1);
							border-radius: 10px;
							padding: 0 8px;
						}
					}
					.listTitle{
						padding-left: 16px;
						line-height: 27px;
						height: 27px;
						background-color: var(--theme-opacity-color);
						color: var(--theme-color);
						position: relative;
						.listTitleLine{
							position: absolute;
							top: 5px;
							left: 0;
							width: 2px;
							height: 16px;
							border-radius: 1px;
							background-color: var(--theme-color);
						}
					}
					.singleOrderItem1{
						padding-left: 53px;
						.itemQty{
							top: 13px;
						}
						.itemInfoRight{
							left: 53px;
						}
					}
					.singleOrderItem:nth-child(3)::before{
						display: none;
					}
				}
			}
		}
		.confirmKitchen{
		  @include floatPanel;
		  
		  .confirmKitchenInner{
		    @include floatPanelInner;
		    z-index: 60;
		    left: 0;
		    right: 0;
		    top: 0;
		    bottom: 0;
		    border-radius: 0;
		    padding: 22px 20px 60px;
		    background: #f6f6f8;
			display: flex;
			flex-direction: column;
		  }
		  .confirmTitle{
		  	font-size: 15px;
		  	font-weight: 600;
			margin-bottom: 20px;
		  }
		  .closeBtn{
		    position: absolute;
		    right: 20px;
		    top: 20px;
		    width: 14px;
		    height: 14px;
		    padding: 5px;
		    cursor:pointer;
		    background-position: center center;
		    background-repeat: no-repeat;
		    background-size:14px;
		  }
		  .confirmDishes{
		    padding-top: 20px;
		  }
		  .customPromptContent{
			  flex: 1;
			  overflow: auto;
		  }
		}
	}

	.givingIcon{
		position: fixed;
		right: 0;
		bottom: 100px;
		width: 68px;
		height: 36px;
		line-height: 36px;
		padding-right: 10px;
		text-align: center;
		background: #fff;
		border-radius: 20px 0 0 20px;
		cursor: pointer;
		box-shadow: 0 0 12px 2px rgba(0,0,0,0.15);
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.giftBox{
			position: absolute;
			left: 5px;
			top: -2px;
			width: 36px;
			height: 36px;
			color: var(--theme-color);
			margin-right: 5px;
			margin-bottom: 2px;
			animation: gogogo 6s ease infinite;
			
		}
		&:hover{
			.giftBox{
				animation: shake 0.3s ease infinite;
			}
		}
	}
	.delayTimeOuter{    
		@include floatPanel;
		z-index: 62;
	}
	.delayTimeInner{    
		@include floatPanelInner;
		z-index: 62;		
		padding: 32px 24px 24px 24px;
		border-radius: 10px 10px 0 0;
		text-align: left;
		display: flex;
		flex-direction: column;
		width: auto;   
		height: auto;
		max-height: 80%;
		left: 0;
		right: 0;
		bottom: 0;
		top: auto;
		margin-top: 0;
		margin-left: 0;
		.otherLanguage{
			font-weight: 600!important;
		}
		.floatPanelHeader{
			width: 100%;
			height: 40px;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
		}
		.closeBtn{
			width: 50px;
			height: 50px;
			float: right;
			cursor:pointer;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 20px;
		}
		.delayTimeTitle{
			line-height: 23px;
			margin: 0;
			font-size: 17px;
			font-weight: normal;
			letter-spacing: -0.02em;
		}
		.delayTimeMain{
			flex: 1;
			overflow: auto;
		}
		.delayTimeCont{
			margin: 16px 0 0;
			dt{
				line-height: 22px;
				font-size: 16px;
				font-weight: 600;
				margin-bottom: 5px;
			}
			dd{
				margin-left: 0;
				display: flex;
				align-items:flex-start;
				font-size: 16px;
				line-height: 22px;
			}
			.circle{
				display: inline-block;
				width: 4px;
				height: 4px;
				margin-top: 9px;
				border-radius: 4px;
				background: #4f4f4f;
				margin-right: 5px;
			}
			.reedemDiscountCont{
				display: block;
				padding-bottom: 12px;
				span{
					color: var(--theme-color);
				}
			}
		}
		.delayTimeFoot{
			display: flex;
			justify-content: space-between;
			margin: 16px 10px 0 10px; 
			.confirmBtn{
				height: 44px;
				line-height: 44px;
				background-color: var(--theme-color);
				color: rgba(0,0,0,0.8);
				border-radius: 10px;
				border: none;
				font-size: 16px;
				border-radius: 10px;
				text-align: center;
				color: var(--color-btn-text);
				border: none;
				cursor: pointer;
				.floatBtn{
					width: 100%;
				}
			}
		}
		.delayTimeFoot>button,.delayTimeFoot>div{
			width: calc(50% - 8px);
			color: #120136;
			font-size: 16px;
			height: 44px;
		}
	}
	
	@keyframes shake {
		0%{
		  transform: rotate(0);
		}
		25%{
		  transform: rotate(10deg);
		}
		50%{
		  transform: rotate(0deg);
		}
		75%{
		  transform: rotate(-10deg);
		}
		100%{
		  transform: scale(0deg);
		}
	}
	@keyframes gogogo
	{
		0% { 
			transform: rotate(0);    
		}
		2% {
			transform: rotate(10deg);    
		}
		4% {
			transform: rotate(-10deg);    
		}
		6% {
			transform: rotate(10deg);    
		}
		8% {
			transform: rotate(-10deg);    
		}
		10% {
			transform: rotate(0);    
		}
		30%{
			left: 5px; 
			transform: rotate(30deg);        
		}
		50%{
			left: 15px;
			transform: rotate(0);
		}
		54% {
			transform: rotate(10deg);    
		}
		68% {
			transform: rotate(-10deg);    
		}
		62% {
			transform: rotate(10deg);    
		}
		64% {
			transform: rotate(-10deg);    
		}
		68% {
			transform: rotate(10deg);    
		}
		70%{
			left: 15px;
			transform: rotate(0);
		}    
		100%{ 
			left:5px;
			transform: rotate(-360deg);
		}
	}
	
	.swiperPagination{
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 15px;
		position: initial;
		margin-top: -10px;
		margin-bottom: 0;
		span{
			margin: 0 2px;
			&.bullet{
				background-color: #C4C4C4;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				cursor: pointer;
			}
			&.bulletActive{
				background-color: var(--theme-color);
			}
		}
	}

    .verifyCodeCon{
        width: 100vw;
        max-width: 520px;
        padding: 44px;
        color: rgba(0, 0, 0, 0.80);
        .title{
            font-size: 24px;
            font-weight: 600;
        }
        .subTitle{
            font-size: 14px;
            font-weight: 400;
            padding-top: 16px;
            span{
                font-weight: 600;
                padding-left: 5px;
            }
        }
        .btnCon{
            padding-top: 32px;
            .submitBtn{
                margin-bottom: 16px;
            }
        }
    }
    
    .emailExistTip{
        width: 100vw;
        max-width: 420px;
        padding: 72px 42px 42px;
        color: rgba(0, 0, 0, 0.80);
        font-size: 16px;
        span{
            color: #1055ef;
            padding: 0 3px;
            font-weight: bold;
            cursor: pointer;
        }
        >button{
            margin-top: 30px;
        }
    }
	.orderReminder{
		padding: 24px;
		width: 100%;
		.orderReminderCont{
			color:rgba(0, 0, 0, 0.80); 
			text-align: center;
			font-size: 24px;
			font-weight: 600;
			line-height: 32px;
			margin-bottom: 24px;
		}
		.orderReminderText{
			color:rgba(0, 0, 0, 0.80);
			font-size: 16px;
			font-weight: 600;
			line-height: 22px;
			text-align: left;
			margin-top: 24px;
		}
		.orderReminderFooter{
			padding-top: 32px;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}
		.orderReminderFooter button{
			margin-bottom: 16px;
		}
		.anotherOrderBtn{
			height: 56px;
			line-height: 56px;
			text-align: center;
			font-size: 16px;
			font-weight: 600;
			color: rgba(0, 0, 0, 0.80);
			background: rgba(0, 0, 0, 0.03);
			border-radius: 10px;
			cursor: pointer;
		}
	}
}

.addressDialogWrap{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.6);
	z-index: 10;
	.addressDialog{
		position: absolute;
		width: 92%;
		max-width: 400px;
		background-color: #fff;
		border-radius: 16px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	
		.header{
			display: flex;
			padding-left: 24px;
			align-items: center;
			span{
				flex: 1;
				font-size: 20px;
				font-weight: 600;
			}
			.closeBtn{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 60px;
				height: 60px;
				cursor: pointer;
				img{
					width: 24px;
					height: 24px;
				}
				&:hover{
					img{
						width: 28px;
						height: 28px;
					}
				}
			}
		}
		.formCon{
			padding: 5px 20px 30px;
			.form{
				input{
					height: 36px;
					line-height: 36px;
					margin-top: 12px;
					font-size: 16px;
				}
			}
		}
	}
}

@media screen and (min-width: 769px){
	.bagPageNew{
		.topTipWrap{
			padding: 20px 16px;
			font-size: 14px;
		}
		.saveMoneyText{
			line-height: 24px;
		}
		.bagPageBody{
			&::-webkit-scrollbar {
				width: 0;
				height: 0;
				display: none;
				-ms-overflow-style: none;
				overflow: -moz-hidden-unscrollable;
			}
			.bagContent{
				display: flex;
				height: calc(100% - 60px);
				.bagLeft{
					flex: 1;
					background-color: #fff;
					height: 100%;
					max-height: 100%;
					overflow-y: auto;
					padding: 20px;
					border-bottom: 1px solid rgba(0,0,0,0.1);
					&::-webkit-scrollbar{
						width: 8px;
						background-color:#fafafa;
					}
					&::-webkit-scrollbar-track{
						border-radius:10px;
						background-color:#fafafa;
					}
					&::-webkit-scrollbar-thumb{
						border-radius:10px;
						background-color:#dedede;
					}
					.notificationMessage{
						box-shadow: none;
						border: 1px solid var(--theme-opacity-color3);
						border-radius: 0;
						font-size: 14px;
						line-height: 24px;
						padding:14px;
						margin: 0;
					}
					.cardSection{
						margin-bottom: 0;
						padding: 0;
						.cardBx{
							border-bottom: 1px solid rgba(0,0,0,0.1);
							padding: 12px 16px;
							position: relative;
							.cardContent{
								.timePickerBx{
									select{
										font-size: 15px !important;
									}
								}
							}
						}
						&:nth-last-child(1){
							.cardBx:nth-last-child(1){
								border: none;
							}
						}
					}
                    
                    .phoneLoginAgreement{
                        font-size: 12px;
                    }
				}
				.bagRight{
					width: 366px;
					margin-left: 20px;
					height: 100%;
					.bagRightContent{
						background-color: #fff;
						max-height: calc(100% - 74px);
						overflow-y: auto;
						padding: 0 4px;
						.orderPriceDetailBx{
							padding: 6px 15px;
						}
						.itemListBx{
							padding: 0 12px;
						}
					}
					.confirmBtn{
						min-height: 74px;
						padding: 12px 20px;
						position: static;
						background-color: #fff;
						.payErr{
							padding: 0 20px 10px;
						}
					}
				}
				.loyaltyMember{
					box-shadow: 0px 2px 10px rgba(18, 1, 54, 0.05);
					.joinFree{
						text-align: center;
						.joinFreeBtn{
							width: 180px;
							height: 30px;
							line-height: 30px;
							font-size: 16px;
						}
					}
				}
			
				.cardBx .cardContent.deliveryAddress .addressInfo {
					p{
						width:calc(100% - 100px);
					}
					.editAddressBtn{
						width: 100px;
					}
				}
			}
			.bagPageFooter{
				padding-top: 16px;
			}
		}
		.delayTimeInner{
			width: 410px;
			height: 300px;
			top: 50%;
			left: 50%;
			margin-top: -150px;
			margin-left: -200px;
			padding-top: 30px;			
			border-radius: 10px;
		}
		.orderReminder{
			max-width: 390px;
			
			.anotherOrderBtn{
				height: 44px;
				line-height: 44px;
			}
		}
	}
}

@media screen and (min-width: 1080px){
	.bagPageNew{
		display: block;	
		.bagPageBody{
			height: 100%;
			max-width: 1480px;
			margin: 0 auto;
			padding-left: 70px;
			padding-right: 70px;
			.bagContent{
				.bagLeft{
					.cardBx{
						display: flex;
						.cardTitle{
                            min-width: 200px;
                            width: 30%;
                            max-width: 235px;
							span.tipAmount{
								display: block;
								position: absolute;
								left: 16px;
								bottom: 10px;
							}
							.chooseStatus{
								margin-left: 4px;
								margin-right: 8px;
							}
						}
						.cardContent{
							flex: 1;
						}
					}
				}
				.bagRight{
					.cardTitle{
						padding-left: 12px;
						padding-top: 8px;
					}
				}
			}
		}
	}
}
