.paymentLabel{
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    display: flex;
    background: #f3f3f3;
    cursor: pointer;
    border-radius: 10px;
    .paymentCont{
        display: flex;
        flex: 1;
        flex-direction: row;
		align-items: center;
    }
    .paymentType{
        display: flex;
        flex-direction: column;
    }
    .paymentIcon{
        margin-left: 8px;
        margin-right: 8px;
        font-size: 30px;
    }
    .selectIcon{
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        font-size: 18px;
    }
	.gpayIcon{
		width: 42px;
		height: 22px;
		margin-right: 8px;
		margin-left: 8px;
	}
}