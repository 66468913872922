.verifyCodeCon{
  padding: 24px;
  .headerTitle{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
    color: rgba(0, 0, 0, 0.80);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    .backBtn{
      width: 32px;
      height: 32px;  
      margin-right: 8px;
      i{  
          width: 100%;
          height: 100%;          
          font-size: 32px;
          font-weight: 400;
      }
    }
  }
  .desc{
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 24px;
  }
  .phonenumBx{
    margin-bottom: 16px;
    .phoneInputBX{
      position: relative;
    }
    .countryCode{
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translate(0, -50%);
      padding-right: 16px;
      color: rgba(0, 0, 0, 0.80);
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      border-right: 2px solid rgba(0, 0, 0, 0.2);
    }
    .phoneInput{
      width: 100%;
      height: 56px;
      padding: 0px 16px 0 70px;
      line-height: 56px;
      border: 2px solid transparent;
      outline: none;
      border-radius: 10px;
      background-color: #f2f2f2;
      font-size: 16px;
      font-weight: 600;
      -webkit-appearance: none;
	  caret-color: #FFB600;	
      &:hover{
          border: 2px solid rgba(0, 0, 0, 0.8);
      }
      &:focus{
          background-color: #fff;
          border: 2px solid rgba(0, 0, 0, 0.8);
      }
      &::placeholder{
          font-weight: 400;
          color: rgba(0, 0, 0, 0.2);
      }
    }
    i.clearInput{
      width: 24px;
      height: 24px;
      position: absolute;
      right: 16px;
      top: 50%;
      font-size: 24px;
      color: #333333;
      cursor: pointer;
      transform: translate(0, -50%);
    }
  }
  
  .phoneErrTip{
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: red;
  }
  
  .policyBx{
    margin: 20px 0;
    .textGray{
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.7);
    }
    .textBlack{
        color: var(--theme-color);
        text-decoration: underline;
        cursor: pointer;
    }
}
}
@media screen and (max-width:480px) {  
  .verifyCodeCon{
    width: auto;
    height: calc(100% - 48px);
  }  
}
@media screen and (min-width:481px) {  
  .verifyCodeCon{
    width: 342px;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .phonenumBx{
      .phoneInput{
        height: 44px;
        line-height: 44px;
      }
    }
    .backBtn{
      position: absolute;
      left: 24px;
      top: 24px;
      display: flex;
      align-items: center;
      height: 32px;  
      margin-right: 8px;    
      font-size: 20px;
      cursor: pointer;
      i{  
        width: 32px;
        height: 100%;          
        font-size: 32px;
        font-weight: 400;
      }
    }
  }
}