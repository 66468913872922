.defaultWrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.comboPanelOut {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	overflow: auto;
    z-index: 100;

    .comboPanelInner {
        width: 100%;
        height: 100%;
    }
}

.comboPanel {
    position: relative;
    width: 100%;
	height: 100%;
	overflow: hidden;
    background: rgba(0, 0, 0, 0.65);

    .comboPanelContainer {
        width: 100%;
        height: 100%;
        min-height: 329px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;

        .comboPanelContent {
            flex: 1;
            width: 100%;
        }
    }

    .notEnfonts {
        font-weight: 600;
    }

    .main {
        position: absolute;
        top: 10%;
        bottom: 10%;
        left: 0;
        right: 0;
        z-index: 100;
        background: var(--white-background-color);
        margin: auto;
        max-width: 898px;
        min-height: 329px;
        display: flex;
        flex-flow: row wrap;
        border-radius: 6px;
        overflow: hidden;
    }

    .mainLeft {
        width: 100%;
        padding: 0;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        &.emptyMainLeft{
            display: none;
        }
        .itemImg {
            width: 100%;
            height: 377px;
            margin-top: 8px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .loadThumbPath {
            height: 377px;
            background-color: #f6f6f6;
            background-image: url("../../images/error_img.png");
            background-size: 100px;
        }

        .haveImg {
            padding-top: 10px;
        }
    }

    .mainRight {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        padding-bottom: 20px;
    }

    .mainRight>div {
        text-align: left;
    }

    .closeMobile {
        // position: absolute;
        z-index: 99;
        display: flex;
        margin-top: 24px;
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        line-height: 32px;
        background: #fff;
        color: #000;
        transition: 0.5s opacity;
        opacity: 0;
        display: none;

        i {
            font-size: 14px;
        }

        .fixedName {
            flex: 1;
            align-items: flex-start;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: rgba(0, 0, 0, 0.80);
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            padding-right: 24px;
            margin-left: 8px;
            color: #000;
        }

        &.show {
            opacity: 1;
            display: flex;
        }

        &.isFixed {
            border-bottom: 4px solid rgba(0, 0, 0, 0.05);
        }
    }

    .close,.backBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-left: 24px;
        cursor: pointer;
        border-radius: 50%;
        text-align: center;
        z-index: 101;
        transition: 0.5s opacity;
        opacity: 0;
        display: none;
        &.show {
            opacity: 1;
            display: flex;
        }
    }
    .close i {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        font-size: 32px;
        color: rgba(0, 0, 0, 0.8);
    }
    .backBtn i{
        font-size: 32px;
    }

    .mrHeader {        
        .itemName {
            color: rgba(0, 0, 0, 0.80);
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            overflow: hidden;
            word-break: break-word;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            font-weight: bold;
            -webkit-box-orient: vertical;
            flex: 1;
        }
        .labels{
            display: flex;
            flex-wrap: wrap;
        }
    }

    .label {
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 10px;
        margin-right: 4px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        padding: 0 4px 2px 8px;
        font-size: 14px;
        font-weight: 600;

        img {
            width: 14px;
            margin-left: 2px;
        }

        &.newLabel {
            background-color: rgba(255, 182, 0, 0.05);
            color: rgba(242, 110, 33, 1);
        }
        &.rawLabel{										
            background-color: rgba(194, 22, 12, 0.05); 
            color: rgba(194, 22, 12, 1);

        }
        &.recommendedLabel{
            padding: 0 8px 2px 8px;
            background-color: rgba(211, 101, 0, 0.05); 
        }
        &.spicyLabel{
            background-color: rgba(121, 26, 23, 0.05);
            color: rgba(148, 27, 27, 1);
        }
        &.imitationLabel{			
            background-color: rgba(216, 69, 91, 0.05);
            color: rgba(216, 69, 91, 1)
        }
        &.veggieLabel{										
            background-color: rgba(95, 137, 64, 0.05);
            color: rgba(95, 137, 64, 1);
        }
        &.shellfishLabel{				
            background-color: rgba(151, 129, 93, 0.05);
            color: rgba(151, 129, 93, 1);
        }
        &.ayciLabel{
            background-color: rgba(206, 82, 140, 0.05);
            color: rgba(206, 82, 140, 1);
        }
        &.coldLabel{
            background-color: rgba(111, 216, 255, 0.05);
            color: rgba(111, 216, 255, 1);
        }
        &.hotLabel{
            background-color: rgba(244, 67, 54, 0.05);
            color: rgba(244, 67, 54, 1)
        }
        &.gfLabel{
            background-color: rgba(199, 143, 64, 0.05);
            color: rgba(199, 143, 64, 1);
        }
        &.veganLabel{
            background-color: rgba(137, 156, 55, 0.05);
            color: rgba(137, 156, 55, 1);
        }
        &.caLabel{
            background-color: rgba(60, 80, 12, 0.05);
            color: rgba(60, 80, 12, 1);
        }
        &.caffeineLabel{				
            background-color:rgba(95, 137, 64, 0.05);
            color: rgba(155, 78, 0, 1);
            img{
                width: 17px;
                transform: rotate(-7deg);
            }
        }
    }

    .mrSubHeader {
        margin-top: 8px;
        flex: 0 0 auto;
        color: #4f4f4f;
        word-break: break-word;
        
        cursor: pointer;

        .otherDescription{
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.5);
            margin-bottom: 10px;
        }
        .description{
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.5);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            &.withOther{
                -webkit-line-clamp: 3;
            }
        }
    }

    .showAllDescription {
        .description{
            -webkit-line-clamp: unset;
            &.withOther{
                -webkit-line-clamp: unset;
            }
        }
    }

    .mrBut {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 142px;
        height: 42px;
        padding: 0px 8px;
        background: rgba(255, 255, 255, 1);
        color: rgba(0, 0, 0, 0.8);
        border-radius: 20px;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
        i{
            cursor: pointer;
        }
    }
    .mrBut .mrQuantity {
        flex: 1;
        color:rgba(0, 0, 0, 0.80);
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
    }

    .quantityBx{
        display: flex;
        justify-content: center;
        margin: 16px 0 32px;
    }

    .mrBody {
        flex: 1 1 10px;
        font-size: 22px;
        -webkit-overflow-scrolling: touch;
    }

    .mrSection {
        display: flex;
        flex-flow: row wrap;
        padding: 24px 24px 16px;
        border-top: 4px solid rgba(0, 0, 0, 0.05);
    }

    .mrBody>div:first-child .mrSection {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
    }    

    .mrOptionColumn {
        padding: 12px 0;
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        &.hasCheckedOption{
            border-bottom: none;
        }
    }
    .mrBody>div:last-child .mrSection {
        padding-bottom: 0;
        .mrOptionColumn:last-child{            
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
    }
    .mrOptionColumn:last-child {
        border-bottom: none;
    }
    .secondMenuColumn{
        background-color: #fff;
    }
    .mrBody .secondMenuColumn:last-child {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.05)
    }
    .optionBlock{
        display: flex;
        flex-flow: row wrap;
        padding: 32px 24px 16px;
        border-bottom: 4px solid rgba(0, 0, 0, 0.05);
        &:last-child{
            border-bottom: 0;
            padding-bottom: 0;
        }
        .mrOption{
            padding: 16px 0;
            margin-top: 0;
        }
    }
    .optionColumn{ // 没有suboptions的option和有suboptions的option混合
        display: flex;
        padding: 0 24px;
        &.nextHasSubOption{
            border-bottom: 4px solid rgba(0, 0, 0, 0.05);
        }
        .mrTitle{
            display: flex;
            justify-content: space-between;
            padding: 16px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            .mrTitleUp{
                color: rgba(0, 0, 0, 0.80);
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
            }
        }
    }


    .mrTitle {
        flex: 0 0 100%;
        font-size: 16px;
        color: #000;
    }

    .mrTitleUp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(0, 0, 0, 0.80);
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
    }

    .mrTitle>span {
        // border-bottom:solid var(--border-color) 1px;
    }

    .sectionTitle {
        margin-top: 4px;
        color: rgba(0, 0, 0, 0.50);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    .requiredDefault {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        border-radius: 6px;
        color:  rgba(0, 0, 0, 0.50);
        font-size: 12px;
        font-weight: 400;
        height: 16px;
        line-height: 16px;
        white-space: nowrap;
        background: rgba(0, 0, 0, 0.03);
        letter-spacing: normal;
        font-family: "Microsoft YaHei","Arial","-apple-system","sans-serif";
    }

    .isShowRequiredIcon {
        display: flex;
        align-items: center;
        padding-right: 4px;
        color: rgba(82, 153, 49, 1);
        background: rgba(238, 245, 234, 1);
    }

    .requiredIcon {
        font-size: 14px;
        font-weight: normal;
        color: rgba(82, 153, 49, 1);
    }

    .isCheck {
        background: rgba(254, 236, 235, 1);
        color: rgba(244, 67, 54, 1);
    }

    .isShake {
        // animation: move 1.5s 0s;
    }

    @keyframes move {
        0% {
            transform: translateX(0px);
        }

        8% {
            transform: translateX(6px);
        }

        16% {
            transform: translateX(-6px);
        }

        24% {
            transform: translateX(6px);
        }

        32% {
            transform: translateX(-6px);
        }

        40% {
            transform: translateX(4px);
        }

        48% {
            transform: translateX(-4px);
        }

        56% {
            transform: translateX(3px);
        }

        64% {
            transform: translateX(-3px);
        }

        72% {
            transform: translateX(2px);
        }

        80% {
            transform: translateX(-2px);
        }

        88% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(0px);
        }
    }
    .mrShake{
        background: rgba(255, 251, 242, 0);
        animation: mrShake 1.8s 0s;
    }
    @keyframes mrShake {
        0% {
            background: rgba(255, 251, 242, 0);
        }

        60% {
            background: rgba(255, 251, 242, 1);
        }

        100% {
            background: rgba(255, 251, 242, 0);
        }
    }
    .mrTitleIcon {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: var(--theme-color);
        vertical-align: 3px;
        transform: rotate(45deg);
        margin-right: 12px;
        margin-left: 5px;
    }

    .specialRequest {
        margin-top: 12px;
    }
    .allowTogo{
        height: 32px;
        margin: 0 24px 16px;
        .allowTogoInner{
            display: inline-block;
            height: 32px;
            padding: 5px 12px 5px 12px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 20px;       
            cursor: pointer; 
            .allowTogoIcon{
                float: left;
                margin-right: 4px;
                width: 16px;
                height: 16px;
                font-size: 16px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.8);
            }
            span{
                float: left;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.8);
            }
            .checkIcon{
                float: left;
                display: none;
                margin-left: 2px;
            } 
            &.checked{
                border-color: rgba(238, 245, 234, 1);
                background: rgba(238, 245, 234, 1);
                .allowTogoIcon,span,.checkIcon{
                    color: rgba(82, 153, 49, 1);
                }
                .checkIcon{
                    display: block;
                } 
            }  
        } 
    }

    .AllergieTextareaOuter {
        position: relative;
        padding: 0;
    }

    .AllergieMaxLength {
        position: absolute;
        right: 20px;
        bottom: 2px;
        font-size: 14px;
        color: #999999;
    }

    .mrOption {
        display: flex;
        flex: 0 0 100%;
        margin-top: 8px;
        font-weight: 400;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        padding-left: 1px;
    }

    .columnIconBox {
        width: 24px;

        &.columnIconBoxQuantity {
            width: 84px;
        }
    }

    .itemDisabled {
        pointer-events: none;
        color: #BDBDBD;

        .columnIcon {
            color: #E0E0E0 !important;
        }

        .optionName {
            color: #BDBDBD !important;
        }

        .optionPrice {
            color: #BDBDBD !important;
        }
    }

    .optionPrice {
        color: rgba(0, 0, 0, 0.80);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    .itemPrice{
        display: flex;
        .optionPrice{
            margin-right: 5px;
        }
        .labels{
            margin-top: 0;
            padding-top: 1px;
            width: 0;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            .label{
                font-size: 12px;
                font-weight: normal;
            }
        }
    } 
    .strikethroughStyle{
        font-size: 12px;
        line-height: 19px;
        color: #828282;
        font-weight: 400;
        text-decoration: line-through;
        margin-right: 8px;
    }

    .mrFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        background: #fff;
    }
	
    .showTotal {
        // flex:0 0 10px;
        text-align: center;
        line-height: 82px;
        padding-right: 30px;
        font-size: 18px;
        color: var(--theme-text-color);
    }

    .rmSubmitBnt {
        font-size: 18px;
        color: var(--color-btn-text);
        white-space: nowrap;
        .circula {
            display: inline-block;
            width: 4px;
            height: 4px;
            margin: 0 4px;
            border-radius: 4px;
            background: var(--color-btn-text);
        }
		
		&.noThanks{
			min-width: 120px;
			width: 120px;
			margin-top: 10px;
			margin-bottom: 10px;
			background-color: #fff;
			border: 1px solid #dedede;
			color: #333;
		}
		&.small{
			font-size: 16px;
			height: 42px;
		}
    }

    .icon {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.8);
    }

    .iconGrey {
        color: rgba(0, 0, 0, 0.2);
    }

    .columnIconBox {
        display: flex;
        cursor: pointer;
        align-items: center;
    }

    .columnIcon {
        color: #333;
        font-size: 24px;
        width: 24px;
        height: 24px;
    }

    .checkRadio {
        position: relative;
        width: 24px;
        height: 24px;
        font-size: 24px;
        border-radius: 50%;
        display: flex;
        transition: all 0.15s;
        cursor: pointer;
    }

    .checkBox {
        width: 24px;
        font-size: 24px;
        cursor: pointer;
        padding: 4px;
    }
    .checkBox,.checkRadio{        
        span{
            display: none;
        }
        span:nth-child(1){
            display: block;
        }
    }

    .canNotCheck {
        color: rgba(217, 217, 217, 1);

        .columnIcon {
            color: rgba(217, 217, 217, 1);
            transition: 0.2s all;
        }

        .optionName {
            color: #BDBDBD;
            transition: 0.2s all;
        }

        .optionPrice {
            color: #BDBDBD;
            transition: 0.2s all;
        }
        .hasMore{
            color: #BDBDBD;
            transition: 0.2s all;
        }
    }

    .notAllowSelectMore {
        color: #E0E0E0;
    }
    .selectMinus{
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 24px;
        color: rgba(255, 255, 255, 1);
        background: rgba(0, 0, 0, 0.8);
        border-radius: 24px;
        font-family: "Microsoft YaHei","Arial","-apple-system","sans-serif";
        &.notAllowSelectMore{
            color: rgba(0, 0, 0, 0.2);
            background: rgba(0, 0, 0, 0.05);
        }
    }
    .selectPlus{
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 24px;
        color: rgba(255, 255, 255, 1);
        background: rgba(0, 0, 0, 0.8);
        border-radius: 24px;
        font-family: "Microsoft YaHei","Arial","-apple-system","sans-serif";
        &.notAllowSelectMore{
            color: rgba(0, 0, 0, 0.2);
            background: rgba(0, 0, 0, 0.05);
        }
    }
    .notselectPlus {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.8);
        background: rgba(0, 0, 0, 0.05);
        border-radius: 24px;
        &:hover{
            background: rgba(0, 0, 0, 0.1);
        }
    }

    .selectOpQuity {
        display: inline-block;
        width: 24px;
        margin: 0 8px;
        text-align: center;
        color:  rgba(0, 0, 0, 0.80);
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
    }

    .optionlabel {
        flex: 1;
        align-items: center;
        cursor: pointer;

        .optionName {
            display: flex;
            color: rgba(0, 0, 0, 0.80);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            font-family: "OpenSans";
            flex-wrap: wrap;

            .label{
                font-size: 12px;
                font-weight: normal;
                &:nth-child(1){
                    margin-left: 5px;
                }
            }
        }

        .soldOut {
            margin-left: 8px;
        }
    }

    .checked {
        font-weight: normal;
        color: #000
    }

    .fixedUntilMaxSection {   
        width: 100%;    
        padding: 12px; 
        margin-top: 8px;
        margin-bottom: 6px;
        background: rgba(0, 0, 0, 0.03);
        border-radius: 10px;
    }

    .fixedUntilMax {
        padding: 0 16px 2px 0;
        font-size: 12px;
    }
    .fixedUntilMaxTitle{
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 2px;
    }
    .fixedUntilMaxChargeTitle{
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        margin-top: 8px;
        margin-bottom: 2px;
    }
    .checkedItemsBx{
        display: flex;
        flex-wrap: wrap;
    }
    .checkedItem{
        display: flex;
    }

    .fixedUntilMaxPrice {
        padding-left: 5px;
    }

    .iconClose {
        color: #777777;
        padding-left: 5px;
        cursor: pointer;
    }

    .iconfixedClose {
        color: #777777;
        padding-left: 5px;
        cursor: pointer;
        transform: rotate(45deg);
        font-size: 14px;
    }
    .hasMore{
        display: inline-block;
        margin-left: 12px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
    }
    .colorBoxFirst {
        margin-right: 4px;
        margin-bottom: 4px;
        i {
            font-size: 16px;
            color: red;
            margin-right: 0;
        }
    }
    .optionCont{
        padding: 8px 16px;
        margin-bottom: 12px;
        width: 100%;
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.03);
        cursor: pointer;
        .optionList{
            margin-bottom: 8px;
        }
        .selectOption{
            display: flex;
            justify-content: space-between;
            color:  rgba(0, 0, 0, 0.80);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }
        .selectSubOption{
            display: flex;
            justify-content: space-between;
            color: rgba(0, 0, 0, 0.50);
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            .subOptionPrice{
                font-size: 12px;
            }
        }
        .editSelectOptions{
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: rgba(0, 0, 0, 0.80);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            cursor: pointer;
            border-radius: 3px;
            &:hover{
                background-color: rgba(0,0,0,0.025);
            }
            i{
                display: inline-block;
                width: 18px;
                height: 18px;
                line-height: 18px;
                font-size: 18px;
            }
        }
    }

    @media (max-width:768px) {
        .mainRight {
            height: auto;
        }

        .main {
            position: fixed;
            top: 12px;
            left: 0;
            right: 0;
            bottom: 65px;
            z-index: 100;
            background: var(--white-background-color);
            margin: 0;
            overflow-y: auto;
            display: block;
            -webkit-overflow-scrolling: touch;
            height: calc(100% - 65px);
            max-height: calc(100% - 65px);
            width: 100%;
            padding: 0;
            padding-bottom: 65px;
            border-radius: 10px;
        }

        .comboPanelContainer {
            min-height: 299px;
        }

        .checked {
            font-weight: normal;
            color: #000
        }
        
        .mrOptionColumn {
            padding: 16px 0;
        }
        .mrOption {
            flex: 0 0 100%;
            padding: 0;
        }
        
        .labels{
            margin-top: 8px;
        }
        .mrTitle {
            height: auto;
            line-height: 22px;
        }

        .mrTitleIcon {
            width: 0.2rem;
            height: 0.2rem;
            margin-right: 0.4rem;
            margin-left: 0;
        }

        .mainLeft {
            height: auto;
            padding: 0 24px 32px;
            margin-bottom: 32px;
            border-bottom: 4px solid rgba(0, 0, 0, 0.05);
            .itemImg {
                height: auto;
                width: 100%;
            }

            .loadThumbPath {
                height: 200px;
            }
        }
        .optionWindow{
            .mainLeft{
                margin-bottom: 0;
            }
        }
        .mrFooter {
            padding: 8px 24px;
            position: fixed;
            left: 0;
            right: 0;
            bottom:0;
            z-index: 101;
        }
        
        .mrFooter .noThanks{
            &>button{    
                min-width: 100px;
                max-width: 140px;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .comboPanel {
        opacity: 0;
        transition: all 0.25s;
        
        .comboPanelContent{
            overflow: auto;
        }
        .mainCont{
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .mainContInner{
            flex: 1;
            display: flex;
            flex-direction: column;
        }
        .mainRight{
            flex: 1;
        }
        .close {
            z-index: 101;
        }

        .main {
            transform: translateY(100%);
            transition: all 0.3s;
        }

        &.show {
            opacity: 1;

            .main {
                transform: translateY(0);
            }
        }

        &.hide {
            opacity: 0;

            .main {
                transform: translateY(100%);
            }
        }
    }
}

@media screen and (min-width: 769px) {
    .mainRightContent {
        display: flex;
        flex-flow: column nowrap;
        flex: 1;
    }

    .comboPanel {
        opacity: 0;
        transition: opacity 0.25s;
        .comboPanelContent{
            display: flex;
            padding: 24px;
            height: calc(100% - 76px);
        }
        .mainCont{
            position: relative;            
            flex: 1;
            display: flex;
            flex-direction: column;
        }
        .mainContInner{
            flex: 1;
            overflow: auto;
            padding-left: 24px;
            padding-right: 24px;
            overflow-x: hidden;
        }
        .closeMobile {
            border-bottom: 4px solid rgba(0, 0, 0, 0.05);
            .fixedName{
                font-size: 20px;
                line-height: 28px;
                margin-left: 24px;
                text-align: center;
            }
            .close{
                margin-left: 0;
                margin-right: 24px;
            }
        }
        .itemImg {
            margin-bottom: 8px;
        }
        .rowReverse{
            flex-direction: row-reverse;
        }
        .main {
            transform: translateY(100%);
            // transition: transform 0.2s;
        }
        .smallWindow{
            width: 800px;
            .mainContInner{
                padding-left: 32px;
                padding-right: 32px;
            }
        }
        .optionBlock{
            padding: 32px 0 16px;
            &:first-child{
                padding-top: 0;
            }
        }
        .optionColumn{
            padding: 0;
        }

        &.show {
            opacity: 1;

            .main {
                transform: translateY(0);
            }
            .optionWindow{
                top: 50%;
                transform: translateY(-50%);
                bottom: auto;
                .closeMobile{
                    align-items: flex-start;
                }
                .fixedName{
                    margin-left: 0;
                    text-align: left;
                }
                .comboPanelContent{
                    height: calc(100% - 96px);
                    min-height: 380px;
                }
            }
        }

        &.hide {
            opacity: 0;
        }
  
        .thumbPathBox{
            position: relative;
            width: 360px;
            img{
                position: fixed;
                width: 360px;
            }
        }
        .mrSection {
            padding: 24px 24px 12px;
            padding-left: 0;
            padding-right: 0;
        }
        .mrBut {
            i{
                &:hover{
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 24px;
                }
            }
        }
        .allowTogo{            
            margin: 0 0 12px;
        }
        .mrFooter {
            padding: 12px 32px 0 24px;       
        }
        .rmSubmitBnt{
            max-width: 316px;
            margin-left: 24px;
        }
        .optionBlockFirst{
            border-top: none;
        }
        .checkBox,.checkRadio{    
            &.checked:hover{
                span:nth-child(1){
                    display: none;
                }
                span:nth-child(2){
                    display: block;
                }
                span:nth-child(3){
                    display: none;
                }
            }
            &.checked:active{
                span:nth-child(1){
                    display: none;
                }
                span:nth-child(2){
                    display: none;
                }
                span:nth-child(3){
                    display: block;
                }
            }
            &.notChecked:hover{
                span:nth-child(1){
                    display: none;
                }
                span:nth-child(2){
                    display: block;
                }
                span:nth-child(3){
                    display: none;
                }
            }
            &.notChecked:active{
                span:nth-child(1){
                    display: none;
                }
                span:nth-child(2){
                    display: none;
                }
                span:nth-child(3){
                    display: block;
                }
            }
        }
    }
}